import { PreviewTextOverlay } from 'components/Shared';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useTracing } from 'components/Shared/VerifiedPayments/hooks';
import { convertHexToRGBA } from 'utils/color';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTheme } from 'styled-components';
import TotalContainer from 'components/Shared/VerifiedPayments/TotalContainer';
import { transformNumberToLocaleString } from 'utils/numbers';

const VerifiedLogo = styled.div<{ hide: boolean }>`
  position: absolute;
  top: 70px;
  left: 18px;
  width: 64px;
  height: 64px;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
  }

  transition: opacity 0.2s ease;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

const MoneyContainer = styled.div`
  display: flex;
  background: ${({ theme }) => convertHexToRGBA(theme.backgrounds.grey, 0.6)};
  padding: 0 8px;
  margin-right: 8px;
  border-radius: 20px;
  align-items: center;
  white-space: nowrap;
`;

const DollarSign = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
  margin-left: 4px;
`;

const SkeletonContainer = styled.div`
  position: absolute;
  z-index: 3;
  left: 20px;
  bottom: 20px;
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: ${({ theme }) => theme.borderRadius} !important;
`;

const ContainerTotals = styled.div<{ hide: boolean }>`
  position: absolute;
  margin: 0 8px;
  top: 150px;
  left: 0;
  right: 0;
  transition: opacity 0.2s ease;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

interface Props {
  active: boolean;
}

const VerifiedPaymentCustomPreview: React.FC<Props> = ({ active }) => {
  const { t } = useTranslation();
  const { loading, query } = useTracing();
  const {
    totalProfitedFarmers,
    totalPriceInUsd,
    totalQuantityInKg,
    totalPriceInGhs,
  } = query.communitySummary || {};
  const { palette } = useTheme();

  const totals = [
    {
      primaryNumber: {
        value: totalProfitedFarmers,
        unit: null,
        unitBefore: false,
      },
      secondaryNumber: null,
      label: t('tracing.farmers-profited'),
      subLabel: ' ',
    },
    {
      primaryNumber: {
        value: totalQuantityInKg,
        unit: 'kg',
        unitBefore: false,
      },
      secondaryNumber: null,
      label: t('tracing.total-quantity'),
      subLabel: ' ',
      transform: transformNumberToLocaleString,
    },
    {
      primaryNumber: {
        value: totalPriceInUsd,
        unit: '$',
        unitBefore: true,
      },
      secondaryNumber: {
        value: totalPriceInGhs,
        unit: 'GH₵',
      },
      label: t('tracing.total-price'),
      transform: transformNumberToLocaleString,
    },
  ];

  return (
    <div>
      <VerifiedLogo hide={active}>
        <img
          src="https://seedtrace-prd.imgix.net/defaults/koa/koa_verified.png"
          alt="Koa verified"
        />
      </VerifiedLogo>
      {loading ? (
        <SkeletonTheme
          baseColor={convertHexToRGBA(palette.grey['A800'], 0.8)}
          highlightColor={convertHexToRGBA(palette.grey[700], 0.8)}
        >
          <SkeletonContainer>
            <StyledSkeleton width={250} height={30} />
          </SkeletonContainer>
        </SkeletonTheme>
      ) : (
        <Fragment>
          <ContainerTotals hide={active}>
            <TotalContainer totals={totals} />
          </ContainerTotals>
          <PreviewTextOverlay active={active}>
            <MoneyContainer>
              {query?.communitySummary?.totalPriceInUsd
                .toLocaleString()
                .replace(',', ' ')}
              <DollarSign>$</DollarSign>
            </MoneyContainer>
            {t('tracing.total-price')}
          </PreviewTextOverlay>
        </Fragment>
      )}
    </div>
  );
};

export default VerifiedPaymentCustomPreview;
