import { createGlobalStyle } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { DefaultTheme } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { initialTypography } from './typography';
import { CompanyDesignType } from '../types/types';
import { getContrast } from './color';
import { mergeDeep } from 'immutable';
import { device } from 'constants/breakpoints';

export const GlobalStyle = createGlobalStyle<{
  hideScrollBar: boolean;
}>`
  html {
    font-size: 100%;

    @media  ${device.mobile} {
      height: -webkit-fill-available;
      overflow-y: initial;
      font-size: 87.5% !important;
    }
  }

  ${({ hideScrollBar }) =>
    hideScrollBar
      ? `
      document {
        padding: 0;
      }
      body,
      .accordion-draggable {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .accordion-draggable::-webkit-scrollbar,
      body::-webkit-scrollbar {
        display: none;
      }
      `
      : ''}
      
  body {
    color: #0F1412;
    margin: 0px;
    -webkit-font-smoothing: antialiased;

    @media  ${device.mobile} {
      overflow: hidden;
      position: relative;
    }
  }

  #__next {
    position: relative;

    @media  ${device.mobile} {
      height: 100%;
    }
  }
 
  ${({ theme }) =>
    initialTypography(
      // @ts-ignore
      theme.typography.header, // @ts-ignore
      theme.typography.body
    ).toString()};

  h1, h2, h3, h4, h5 {
    font-weight: 700;
  }

  h1 {
    font-weight: 300;
    font-size: 4rem;
  }

  h2 {
    font-size: 1.7rem;
    margin-bottom: 8px;
  }

  ${media.xs`
    h1 {
      font-size: 2.8rem;
    }
  `}

  * {
    box-sizing: border-box;
  }

  img {
    margin-bottom: 0;
  }
`;

export const gridTheme = {
  breakpoints: {
    // defaults below
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 16, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 16, // default 15
    maxWidth: {
      xxl: 1000,
      xl: 1000,
      lg: 900,
    },
  },
};

export interface ThemeType extends DefaultTheme {
  spacing: number;
  breakpoints: {
    xxl: number;
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
  row: {
    padding: number;
  };
  col: {
    padding: number;
  };
  container: {
    padding: number;
  };
  palette: {
    primary: {
      main: string;
      dark: string;
    };
    text: {
      primary: string;
    };
    buttonText: {
      primary: string;
      secondary: string;
    };
    success: {
      main: string;
    };
    error: {
      light: string;
      main: string;
    };
    grey: {
      0: string;
      10: string;
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
      A100: string;
      A200: string;
      A300: string;
      A400: string;
      A500: string;
      A700: string;
      A800: string;
    };
  };
  distances: {
    accordionHeaderHeight: number;
    accordionMargin: number;
    accordionExitHeight: number;
    footerHeight: number;
    mobileMapHeight: number;
    desktopSidebarWidth: number;
    desktopHeaderHeight: number;
    desktopMapAdditionalWidth: number;
    desktopMapAdditionalWidthMd: number;
    mobileTopHeight: number;
    mobileTopHeightLargeScreen: number;
    impactClaimsHeight: number;
  };
  backgrounds: {
    light: string;
    grey: string;
    blueIce: string;
  };
  borderRadius: string;
  borderRadiusButton: string;
  zIndices: {
    map: number;
    menu: number;
    mapOpen: number;
    modal: number;
  };
  typography: {
    header: string;
    body: string;
  };
  shadows: string[];
}

export default {
  spacing: 8,
  palette: {
    primary: {
      main: '#ECB301',
      dark: '#25323a',
    },
    text: {
      primary: '#242424',
    },
    buttonText: {
      primary: '#fff',
      secondary: '#0F1412',
    },
    success: {
      main: '#2DB875',
    },
    error: {
      light: '#FEF6F6',
      main: '#CD282B',
    },
    grey: {
      0: '#FCFCFC',
      10: '#f4f4f4',
      50: '#F4F5FA',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A300: '#5d5d5d',
      A400: '#303030',
      A500: '#afafaf',
      A700: '#616161',
      A800: '#3B3B3B',
    },
  },
  distances: {
    accordionHeaderHeight: 180,
    accordionMargin: 12,
    accordionExitHeight: 100,
    footerHeight: 120,
    mobileHeaderHeight: 60,
    mobileMapHeight: 250,
    desktopSidebarWidth: 280,
    desktopHeaderHeight: 60,
    desktopMapAdditionalWidth: 500,
    desktopMapAdditionalWidthMd: 350,
    mobileTopHeight: 200,
    mobileTopHeightLargeScreen: 210,
    impactClaimsHeight: 70,
  },
  backgrounds: {
    light: '#F9F9F9',
    grey: '#7b7b7b',
    blueIce: '#F4F5FA',
  },
  borderRadius: '8px',
  borderRadiusButton: '4px',
  borderRadiusMedium: '6px',
  zIndices: {
    map: 9999,
    menu: 10000,
    mapOpen: 10001,
    modal: 10002,
  },
  typography: {
    header: 'Inter',
    body: 'Inter',
  },
  shadows: [
    '0px 2px 16px rgba(0, 0, 0, 0.11)',
    '0px 4px 26px 0px rgba(0, 0, 0, 0.12)',
  ],
  ...gridTheme,
};

export const mergeThemes = (
  theme: ThemeType,
  companyDesign?: CompanyDesignType
): ThemeType => {
  if (!companyDesign) return theme;

  const formattedCompanyDesign = {
    typography: {
      header: companyDesign.typography?.header,
    },
    palette: {
      primary: {
        main: companyDesign.palette.primary,
      },
      buttonText: {
        primary: getContrast(companyDesign.palette.primary),
        secondary: getContrast(companyDesign.palette.primary),
      },
    },
  };

  return mergeDeep(theme, formattedCompanyDesign);
};
