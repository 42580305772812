import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  margin-bottom: 6px;

  b {
    margin-left: 4px;
  }
`;

interface Props {
  name: string;
  className?: string;
}

const CompanyName: React.FC<Props> = ({ name, className }: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {t('by')} <b>{name}</b>
    </Container>
  );
};

export default CompanyName;
