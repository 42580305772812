import React, { FC, PropsWithChildren, createContext, useMemo } from 'react';
import { PageDataType } from 'types/types';
import { mapAllImpactClaims, mapAllSupplyChainSteps } from 'utils/data-mapper';
import { ImpactClaimsProvider } from './ImpactClaimsProvider';
interface IPageDataContextValues {
  pageData?: PageDataType;
}

const PageDataContext = createContext<IPageDataContextValues>({
  pageData: undefined,
});

const PageDataConsumer = PageDataContext.Consumer;

interface Props extends PropsWithChildren {
  pageData: PageDataType;
}

const PageDataProvider: FC<Props> = ({ children, pageData }) => {
  const componentImpactClaims = pageData.impactClaims.edges
    .map(({ node }) => node)
    .filter(({ component }) => component);

  /** For now we only show component impact claims on the activities itself. The logic needs to be moved to the backend */
  const filteredImpactClaims = useMemo(
    () => mapAllImpactClaims(pageData.impactClaims),
    [pageData.impactClaims]
  );

  /**
   * Calculation to be moved to the backend
   * Add to each activity in the supply chain the correct component impact claim if the component is part of the activity or connected to one that is part of the activity
   */
  const mappedSupplyChainSteps = useMemo(
    () =>
      mapAllSupplyChainSteps(
        componentImpactClaims,
        pageData.supplyChain.stepsSerialized
      ),
    [componentImpactClaims, pageData.supplyChain.stepsSerialized]
  );

  const state = {
    pageData: {
      ...pageData,
      impactClaims: filteredImpactClaims,
      supplyChain: {
        ...pageData.supplyChain,
        stepsSerialized: mappedSupplyChainSteps,
      },
    },
  };

  return (
    <PageDataContext.Provider value={state}>
      <ImpactClaimsProvider impactClaims={filteredImpactClaims}>
        {children}
      </ImpactClaimsProvider>
    </PageDataContext.Provider>
  );
};

export { PageDataConsumer, PageDataProvider };

export default PageDataContext;
