import { ISupplyChainStepSerialized } from 'types/supplyChain.types';
import { ImpactClaim, ImpactClaimsType } from 'types/types';

export const mapAllImpactClaims = (impactClaims: ImpactClaimsType) => ({
  ...impactClaims,
  edges: impactClaims?.edges.filter((claim) => !claim.node.component),
});

export const mapAllSupplyChainSteps = (
  componentImpactClaims: ImpactClaim[],
  supplyChainSteps: ISupplyChainStepSerialized[]
) => {
  if (componentImpactClaims.length) {
    // Clone the supply chain steps
    let newSupplyChainSteps = [...supplyChainSteps];

    const allStepActivities = supplyChainSteps.flatMap(
      ({ chainStepActivities }) => chainStepActivities
    );

    componentImpactClaims.forEach((impactClaim) => {
      // Build a list beginning with the first step activity ids that contain the component id
      const initialMatchedActivityIds = allStepActivities
        .filter(
          (stepActivity) =>
            impactClaim.component?.id === stepActivity.activity.component?.id
        )
        .map(({ id }) => id);

      // Map through all the connected activities, add newly found connected once to the temporary id array and add the found activity to the new step array
      const connectedStepActivities = new Set();

      const previousMatchedActivityIds = Array.from(
        new Set(initialMatchedActivityIds)
      );
      // Find all the activityIds previous to the current one
      while (previousMatchedActivityIds.length > 0) {
        const matchedActivityId = previousMatchedActivityIds.pop();
        // Add item to the beginning of the array
        connectedStepActivities.add(matchedActivityId);

        // Find all the activityIds previous to the current one
        const previousStepActivitiesToCurrentStep = allStepActivities
          .find(({ id }) => id === matchedActivityId)
          ?.incomingLinks.map(
            ({ chainStepActivitySourceId }) => chainStepActivitySourceId
          );

        previousMatchedActivityIds.push(
          ...Array.from(
            // Ensure uniqueness since ids can be found multiple times
            new Set(
              previousStepActivitiesToCurrentStep.filter(
                (id) => !previousMatchedActivityIds.includes(id)
              )
            )
          )
        );
      }

      // Add impact claim to the all connected activities
      newSupplyChainSteps = newSupplyChainSteps.map((step) => ({
        ...step,
        chainStepActivities: step.chainStepActivities.map((chainActivity) => {
          if (connectedStepActivities.has(chainActivity.id)) {
            return {
              ...chainActivity,
              activity: {
                ...chainActivity.activity,
                impactClaims: {
                  ...chainActivity.activity.impactClaims,
                  edges: [
                    ...(chainActivity.activity?.impactClaims.edges || []),
                    { node: impactClaim, __typename: 'ImpactClaimEdge' },
                  ],
                },
              },
            };
          }
          return chainActivity;
        }),
      }));
    });

    return newSupplyChainSteps;
  } else {
    return supplyChainSteps;
  }
};
