import { FC, PropsWithChildren } from 'react';
import { ISupplyChain, ModeOfTransport } from './supplyChain.types.js';

export interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ImageType {
  __typename?: 'ImageType';
  url: string;
  attribution?: string;
  crop?: Crop;
}

export interface Color {
  __typename: 'ColorType';
  hexCode: string;
}

export type MediaType = ImageType | ImageVariant | Color | Video;

export interface Video {
  __typename: 'Video';
  vimeoId: string;
}

export interface ImageVariant {
  __typename?: 'ImageVariant';
  crop?: Crop;
  height: number;
  id: string;
  imageId: string;
  path: string;
  size: number;
  url: string;
  width: number;
  attribution?: string;
}

export interface CompanyType {
  website: string;
  onlineShop?: string;
  name: string;
  logo: ImageType;
  address: AddressType;
  design?: CompanyDesignType;
  gaTrackingId?: string;
  social: SocialType;
}

export interface AddressType {
  city: string;
}

export interface SocialType {
  facebook: string;
  twitter: string;
  instagram: string;
  enabled: boolean;
}

export interface PageDataType {
  productId: string;
  slug: string;
  currentLang: string;
  offboardingRedirect: string;
  languageConfig: ProductLanguageConfig;
  publishedLanguages: string[];
  impactClaims: ImpactClaimsType;
  title: string;
  webshopUrl: string;
  image: ImageType;
  company: CompanyType;
  contentBlocks: ContentBlockType[];
  supplyChain: ISupplyChain;
  traceableRawMaterialIds: { id: string }[];
  latestBatchTracingDate: Date;
}

export interface ContentBlockType {
  id: string;
  contentType: ContentTypeEnum;
  data?: Record<string, unknown>;
}

export interface AttachmentType {
  id: string;
  title: string;
  url: string;
}

export interface AttachmentNode {
  node: AttachmentType;
}

export interface AttachmentEdge {
  edges: AttachmentNode[];
}

export interface ImpactFactType {
  title: string;
  description: string;
  sdgs: number[];
}

export interface ProductType {
  id?: string;
  title: string;
  description: string;
  link?: string;
  image: ImageType;
}

export interface AboutData {
  image: ImageType;
  headline: string;
  description: string;
}

export interface MoreInfoData {
  mediaItem: MediaType;
  headline: string;
  description: string;
  blockTitle: string;
}

export interface VerifiedPaymentsData {
  image?: ImageType;
  headline?: string;
  description?: string;
}

export interface JourneyWidgetFetchResult {
  productId: string;
  currentLang: string;
  publishedLanguages: string[];
  languageConfig: ProductLanguageConfig;
  impactClaims: ImpactClaimsType;
  isSafari: boolean;
  supplyChain: ISupplyChain;
  traceableRawMaterialIds: { id: string }[];
  latestBatchTracingDate: Date;
  company: {
    design: CompanyDesignType;
    gaTrackingId?: string;
    integrationSettings: {
      widgetType: 'BUTTON' | 'MAP';
      showClaims: boolean;
    };
    social: SocialType;
  };
}

export interface ImpactFactsData {
  headline: string;
  description: string;
  image: ImageType;
  impactFacts: ImpactFactType[];
}

export interface FeedbackData {
  headline: string;
  subtitle: string;
  ratingEnabled: boolean;
}

export interface CompanyDesignType {
  typography: {
    header: string;
  };
  palette: {
    primary: string;
  };
}

export interface ProductLanguageConfig {
  default: string;
  enabled: string[];
  published: string[];
}

export interface DropDownType {
  value: string;
  label: string;
  title?: string;
}

export interface BreadcrumbConfig {
  Icon?;
  icon?: FC;
  title: string;
  action?: () => void;
}

export interface CoordinatesType {
  lat: number;
  lng: number;
}

export interface AnalyticsPayloadType {
  anonymousId: string;
  event: string;
  properties: {
    visitorId: string;
    productId: string;
    sessionId: string;
    byQr?: string;
    contentType?: string;
    path?: string;
  };
  meta?: {
    ts?: number;
  };
}
export interface AnalyticsFake {
  identify: () => void;
  page: () => void;
  track: () => void;
  user: () => Record<string, unknown>;
  getState: () => void;
}

export interface AnalyticsPluginType {
  name: string;
  initialize?: () => void;
  page: ({ payload }: { payload: AnalyticsPayloadType }) => void;
  track: ({ payload }: { payload: AnalyticsPayloadType }) => void;
  loaded: () => boolean;
}

export interface sustainableDevGoals {
  title: string;
  description: string;
  number: number;
  __typename: string;
}
export interface ImpactClaimCategory {
  type: string;
  __typename: string;
}

export interface ImpactCatalogItem {
  id: string;
  title: string;
  definition: string;
  category: ImpactClaimCategory;
  sustainableDevGoals: sustainableDevGoals[];
  iconUrl?: string;
  acceptanceCriteria: {
    description: string;
  }[];
  docRequirements: {
    description: string;
  }[];
  __typename: string;
}

export enum DocumentVisibilityStatus {
  PRIVATE = 'PRIVATE',
  SELECTED = 'SELECTED',
  PUBLIC = 'PUBLIC',
}

export type DocumentCategory = {
  MANAGEMENT_LETTER;
  CERTIFICATE;
  SHAREHOLDER_AGREEMENT;
  PICTURES;
  LABORATORY_TEST;
  REPORT;
  STATEMENT;
  OTHER;
};

export interface ClaimAttachmentType extends AttachmentType {
  path: string;
  createdTimestamp: string;
  expiryDate: string;
  issuedDate: string;
  visibility?: DocumentVisibilityStatus;
  category: DocumentCategory;
}

export interface ClaimAttachmentNode {
  node: ClaimAttachmentType;
}

export interface ClaimAttachmentEdge {
  edges: ClaimAttachmentNode[];
}

type ImpactClaimStatusType =
  | 'CREATED'
  | 'UPDATED'
  | 'REQUESTED'
  | 'APPROVED'
  | 'DENIED'
  | 'ARCHIVED'
  | 'DELETED';
export interface ImpactClaim {
  id: string;
  createdTimestamp: string;
  status: ImpactClaimStatusType;
  attachments: ClaimAttachmentEdge;
  attachmentCount: number;
  impactCatalogItem: ImpactCatalogItem;
  component?: {
    id: string;
    title: string;
  };
  __typename: string;
}

export interface ImpactClaimsType {
  edges: {
    node: ImpactClaim;
    __typename: string;
  }[];
  __typename: string;
}

export interface SdgType {
  title?: string;
  iconUrl?: string;
  color?: string;
  description?: string;
  number?: number;
  icon?: SVGAElement;
}

export interface PageType extends PropsWithChildren {
  title?: string;
  image?: ImageType;
  company: CompanyType;
  contentBlocks: ContentBlockType[];
  webshopUrl?: string;
}

export interface SlideItemProps {
  key: string;
  title: string;
}

export interface ActiveItemUpdate {
  block: ContentTypeEnum;
  isIntersecting: boolean;
  index: number;
}

export enum ContentTypeEnum {
  'ABOUT_BLOCK' = 'ABOUT_BLOCK',
  'FEEDBACK_BLOCK' = 'FEEDBACK_BLOCK',
  'IMPACT_FACTS_BLOCK' = 'IMPACT_FACTS_BLOCK',
  'MORE_INFO_BLOCK' = 'MORE_INFO_BLOCK',
  'PRODUCTS_BLOCK' = 'PRODUCTS_BLOCK',
  'PRODUCT_JOURNEY_BLOCK' = 'PRODUCT_JOURNEY_BLOCK',
  'VERIFIED_PAYMENT_BLOCK' = 'VERIFIED_PAYMENT_BLOCK',
  'IMPACT_CLAIMS' = 'IMPACT_CLAIMS',
  'MAIN_SCREEN' = 'MAIN_SCREEN', // Mobile intro
}

export enum MapType {
  DEFAULT = 'DEFAULT',
  LIMITED_LINKS = 'LIMITED_LINKS',
}

export interface AnimatedPin {
  id: string;
  target: PinTarget;
}

export enum PinTarget {
  MAP = 'MAP',
  NODE = 'NODE',
}

export interface ConnectedLink {
  stepId: string;
  sourceActivity?: {
    id: string;
    locationCoordinates: { lat: number; lng: number };
  };
  targetActivity: {
    id: string;
    locationCoordinates: { lat: number; lng: number };
  };
  transportationMode?: ModeOfTransport;
}
