import { gql } from 'graphql-request';
import { SITE_FRAGMENT } from './sites.fragment';

export const RAW_MATERIAL_FRAGMENT = gql`
  fragment rawMaterialValues on RawMaterial {
    id
    title
    imageUrl
  }
`;

export const COMPONENT_FRAGMENT = gql`
  fragment componentValues on Component {
    id
    title
    rawMaterials {
      ...rawMaterialValues
    }
  }
`;

export const ACTIVITY_FRAGMENT = gql`
  fragment activityValues on Activity {
    id
    description
    title
    langs
    name
    locationCoordinates {
      lat
      lng
    }
    site {
      ...siteValues
    }
    impactClaims {
      edges {
        node {
          ...impactClaimValues
        }
      }
    }
    mediaList {
      ...mediaListType
    }
    component {
      ...componentValues
    }
  }
  ${COMPONENT_FRAGMENT}
  ${SITE_FRAGMENT}
`;

export const CHAIN_STEP_ACTIVITY_FRAGMENT = gql`
  fragment chainStepActivityValues on ChainStepActivity {
    id
    incomingLinks {
      chainStepActivitySourceId
      chainStepActivityTargetId
    }
    activity {
      ...activityValues
    }
  }
  ${ACTIVITY_FRAGMENT}
`;

export const SUPPLY_CHAIN_STEP_SERIALIZED_FRAGMENT = gql`
  fragment supplyChainStepSerializedValues on SupplyChainStepSerialized {
    id
    title
    chainStepActivities {
      ...chainStepActivityValues
    }
  }
  ${CHAIN_STEP_ACTIVITY_FRAGMENT}
`;

export const SUPPLY_CHAIN_FRAGMENT = gql`
  fragment supplyChainValues on SupplyChainSerialized {
    stepsSerialized {
      ...supplyChainStepSerializedValues
    }
    allRawMaterials {
      ...rawMaterialValues
    }
    countryCount
    originCount
    farmCount
  }
  ${SUPPLY_CHAIN_STEP_SERIALIZED_FRAGMENT}
  ${RAW_MATERIAL_FRAGMENT}
`;
